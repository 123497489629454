@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'CustomFont';
    src: url('/public/d.ttf') format('truetype');
    /* Other font formats (woff, woff2, etc.) could also be added here */
}
@font-face {
    font-family: 'CustomFont1';
    src: url('/public/db.ttf') format('truetype');
    /* Other font formats (woff, woff2, etc.) could also be added here */
}